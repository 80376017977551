[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\e900";
}
.icon-home2:before {
  content: "\e901";
}
.icon-home3:before {
  content: "\e902";
}
.icon-home4:before {
  content: "\e903";
}
.icon-home5:before {
  content: "\e904";
}
.icon-home6:before {
  content: "\e905";
}
.icon-bathtub:before {
  content: "\e906";
}
.icon-toothbrush:before {
  content: "\e907";
}
.icon-bed:before {
  content: "\e908";
}
.icon-couch:before {
  content: "\e909";
}
.icon-chair:before {
  content: "\e90a";
}
.icon-city:before {
  content: "\e90b";
}
.icon-apartment:before {
  content: "\e90c";
}
.icon-pencil:before {
  content: "\e90d";
}
.icon-pencil2:before {
  content: "\e90e";
}
.icon-pen:before {
  content: "\e90f";
}
.icon-pencil3:before {
  content: "\e910";
}
.icon-eraser:before {
  content: "\e911";
}
.icon-pencil4:before {
  content: "\e912";
}
.icon-pencil5:before {
  content: "\e913";
}
.icon-feather:before {
  content: "\e914";
}
.icon-feather2:before {
  content: "\e915";
}
.icon-feather3:before {
  content: "\e916";
}
.icon-pen2:before {
  content: "\e917";
}
.icon-pen-add:before {
  content: "\e918";
}
.icon-pen-remove:before {
  content: "\e919";
}
.icon-vector:before {
  content: "\e91a";
}
.icon-pen3:before {
  content: "\e91b";
}
.icon-blog:before {
  content: "\e91c";
}
.icon-brush:before {
  content: "\e91d";
}
.icon-brush2:before {
  content: "\e91e";
}
.icon-spray:before {
  content: "\e91f";
}
.icon-paint-roller:before {
  content: "\e920";
}
.icon-stamp:before {
  content: "\e921";
}
.icon-tape:before {
  content: "\e922";
}
.icon-desk-tape:before {
  content: "\e923";
}
.icon-texture:before {
  content: "\e924";
}
.icon-eye-dropper:before {
  content: "\e925";
}
.icon-palette:before {
  content: "\e926";
}
.icon-color-sampler:before {
  content: "\e927";
}
.icon-bucket:before {
  content: "\e928";
}
.icon-gradient:before {
  content: "\e929";
}
.icon-gradient2:before {
  content: "\e92a";
}
.icon-magic-wand:before {
  content: "\e92b";
}
.icon-magnet:before {
  content: "\e92c";
}
.icon-pencil-ruler:before {
  content: "\e92d";
}
.icon-pencil-ruler2:before {
  content: "\e92e";
}
.icon-compass:before {
  content: "\e92f";
}
.icon-aim:before {
  content: "\e930";
}
.icon-gun:before {
  content: "\e931";
}
.icon-bottle:before {
  content: "\e932";
}
.icon-drop:before {
  content: "\e933";
}
.icon-drop-crossed:before {
  content: "\e934";
}
.icon-drop2:before {
  content: "\e935";
}
.icon-snow:before {
  content: "\e936";
}
.icon-snow2:before {
  content: "\e937";
}
.icon-fire:before {
  content: "\e938";
}
.icon-lighter:before {
  content: "\e939";
}
.icon-knife:before {
  content: "\e93a";
}
.icon-dagger:before {
  content: "\e93b";
}
.icon-tissue:before {
  content: "\e93c";
}
.icon-toilet-paper:before {
  content: "\e93d";
}
.icon-poop:before {
  content: "\e93e";
}
.icon-umbrella:before {
  content: "\e93f";
}
.icon-umbrella2:before {
  content: "\e940";
}
.icon-rain:before {
  content: "\e941";
}
.icon-tornado:before {
  content: "\e942";
}
.icon-wind:before {
  content: "\e943";
}
.icon-fan:before {
  content: "\e944";
}
.icon-contrast:before {
  content: "\e945";
}
.icon-sun-small:before {
  content: "\e946";
}
.icon-sun:before {
  content: "\e947";
}
.icon-sun2:before {
  content: "\e948";
}
.icon-moon:before {
  content: "\e949";
}
.icon-cloud:before {
  content: "\e94a";
}
.icon-cloud-upload:before {
  content: "\e94b";
}
.icon-cloud-download:before {
  content: "\e94c";
}
.icon-cloud-rain:before {
  content: "\e94d";
}
.icon-cloud-hailstones:before {
  content: "\e94e";
}
.icon-cloud-snow:before {
  content: "\e94f";
}
.icon-cloud-windy:before {
  content: "\e950";
}
.icon-sun-wind:before {
  content: "\e951";
}
.icon-cloud-fog:before {
  content: "\e952";
}
.icon-cloud-sun:before {
  content: "\e953";
}
.icon-cloud-lightning:before {
  content: "\e954";
}
.icon-cloud-sync:before {
  content: "\e955";
}
.icon-cloud-lock:before {
  content: "\e956";
}
.icon-cloud-gear:before {
  content: "\e957";
}
.icon-cloud-alert:before {
  content: "\e958";
}
.icon-cloud-check:before {
  content: "\e959";
}
.icon-cloud-cross:before {
  content: "\e95a";
}
.icon-cloud-crossed:before {
  content: "\e95b";
}
.icon-cloud-database:before {
  content: "\e95c";
}
.icon-database:before {
  content: "\e95d";
}
.icon-database-add:before {
  content: "\e95e";
}
.icon-database-remove:before {
  content: "\e95f";
}
.icon-database-lock:before {
  content: "\e960";
}
.icon-database-refresh:before {
  content: "\e961";
}
.icon-database-check:before {
  content: "\e962";
}
.icon-database-history:before {
  content: "\e963";
}
.icon-database-upload:before {
  content: "\e964";
}
.icon-database-download:before {
  content: "\e965";
}
.icon-server:before {
  content: "\e966";
}
.icon-shield:before {
  content: "\e967";
}
.icon-shield-check:before {
  content: "\e968";
}
.icon-shield-alert:before {
  content: "\e969";
}
.icon-shield-cross:before {
  content: "\e96a";
}
.icon-lock:before {
  content: "\e96b";
}
.icon-rotation-lock:before {
  content: "\e96c";
}
.icon-unlock:before {
  content: "\e96d";
}
.icon-key:before {
  content: "\e96e";
}
.icon-key-hole:before {
  content: "\e96f";
}
.icon-toggle-off:before {
  content: "\e970";
}
.icon-toggle-on:before {
  content: "\e971";
}
.icon-cog:before {
  content: "\e972";
}
.icon-cog2:before {
  content: "\e973";
}
.icon-wrench:before {
  content: "\e974";
}
.icon-screwdriver:before {
  content: "\e975";
}
.icon-hammer-wrench:before {
  content: "\e976";
}
.icon-hammer:before {
  content: "\e977";
}
.icon-saw:before {
  content: "\e978";
}
.icon-axe:before {
  content: "\e979";
}
.icon-axe2:before {
  content: "\e97a";
}
.icon-shovel:before {
  content: "\e97b";
}
.icon-pickaxe:before {
  content: "\e97c";
}
.icon-factory:before {
  content: "\e97d";
}
.icon-factory2:before {
  content: "\e97e";
}
.icon-recycle:before {
  content: "\e97f";
}
.icon-trash:before {
  content: "\e980";
}
.icon-trash2:before {
  content: "\e981";
}
.icon-trash3:before {
  content: "\e982";
}
.icon-broom:before {
  content: "\e983";
}
.icon-game:before {
  content: "\e984";
}
.icon-gamepad:before {
  content: "\e985";
}
.icon-joystick:before {
  content: "\e986";
}
.icon-dice:before {
  content: "\e987";
}
.icon-spades:before {
  content: "\e988";
}
.icon-diamonds:before {
  content: "\e989";
}
.icon-clubs:before {
  content: "\e98a";
}
.icon-hearts:before {
  content: "\e98b";
}
.icon-heart:before {
  content: "\e98c";
}
.icon-star:before {
  content: "\e98d";
}
.icon-star-half:before {
  content: "\e98e";
}
.icon-star-empty:before {
  content: "\e98f";
}
.icon-flag:before {
  content: "\e990";
}
.icon-flag2:before {
  content: "\e991";
}
.icon-flag3:before {
  content: "\e992";
}
.icon-mailbox-full:before {
  content: "\e993";
}
.icon-mailbox-empty:before {
  content: "\e994";
}
.icon-at-sign:before {
  content: "\e995";
}
.icon-envelope:before {
  content: "\e996";
}
.icon-envelope-open:before {
  content: "\e997";
}
.icon-paperclip:before {
  content: "\e998";
}
.icon-paper-plane:before {
  content: "\e999";
}
.icon-reply:before {
  content: "\e99a";
}
.icon-reply-all:before {
  content: "\e99b";
}
.icon-inbox:before {
  content: "\e99c";
}
.icon-inbox2:before {
  content: "\e99d";
}
.icon-outbox:before {
  content: "\e99e";
}
.icon-box:before {
  content: "\e99f";
}
.icon-archive:before {
  content: "\e9a0";
}
.icon-archive2:before {
  content: "\e9a1";
}
.icon-drawers:before {
  content: "\e9a2";
}
.icon-drawers2:before {
  content: "\e9a3";
}
.icon-drawers3:before {
  content: "\e9a4";
}
.icon-eye:before {
  content: "\e9a5";
}
.icon-eye-crossed:before {
  content: "\e9a6";
}
.icon-eye-plus:before {
  content: "\e9a7";
}
.icon-eye-minus:before {
  content: "\e9a8";
}
.icon-binoculars:before {
  content: "\e9a9";
}
.icon-binoculars2:before {
  content: "\e9aa";
}
.icon-hdd:before {
  content: "\e9ab";
}
.icon-hdd-down:before {
  content: "\e9ac";
}
.icon-hdd-up:before {
  content: "\e9ad";
}
.icon-floppy-disk:before {
  content: "\e9ae";
}
.icon-disc:before {
  content: "\e9af";
}
.icon-tape2:before {
  content: "\e9b0";
}
.icon-printer:before {
  content: "\e9b1";
}
.icon-shredder:before {
  content: "\e9b2";
}
.icon-file-empty:before {
  content: "\e9b3";
}
.icon-file-add:before {
  content: "\e9b4";
}
.icon-file-check:before {
  content: "\e9b5";
}
.icon-file-lock:before {
  content: "\e9b6";
}
.icon-files:before {
  content: "\e9b7";
}
.icon-copy:before {
  content: "\e9b8";
}
.icon-compare:before {
  content: "\e9b9";
}
.icon-folder:before {
  content: "\e9ba";
}
.icon-folder-search:before {
  content: "\e9bb";
}
.icon-folder-plus:before {
  content: "\e9bc";
}
.icon-folder-minus:before {
  content: "\e9bd";
}
.icon-folder-download:before {
  content: "\e9be";
}
.icon-folder-upload:before {
  content: "\e9bf";
}
.icon-folder-star:before {
  content: "\e9c0";
}
.icon-folder-heart:before {
  content: "\e9c1";
}
.icon-folder-user:before {
  content: "\e9c2";
}
.icon-folder-shared:before {
  content: "\e9c3";
}
.icon-folder-music:before {
  content: "\e9c4";
}
.icon-folder-picture:before {
  content: "\e9c5";
}
.icon-folder-film:before {
  content: "\e9c6";
}
.icon-scissors:before {
  content: "\e9c7";
}
.icon-paste:before {
  content: "\e9c8";
}
.icon-clipboard-empty:before {
  content: "\e9c9";
}
.icon-clipboard-pencil:before {
  content: "\e9ca";
}
.icon-clipboard-text:before {
  content: "\e9cb";
}
.icon-clipboard-check:before {
  content: "\e9cc";
}
.icon-clipboard-down:before {
  content: "\e9cd";
}
.icon-clipboard-left:before {
  content: "\e9ce";
}
.icon-clipboard-alert:before {
  content: "\e9cf";
}
.icon-clipboard-user:before {
  content: "\e9d0";
}
.icon-register:before {
  content: "\e9d1";
}
.icon-enter:before {
  content: "\e9d2";
}
.icon-exit:before {
  content: "\e9d3";
}
.icon-papers:before {
  content: "\e9d4";
}
.icon-news:before {
  content: "\e9d5";
}
.icon-reading:before {
  content: "\e9d6";
}
.icon-typewriter:before {
  content: "\e9d7";
}
.icon-document:before {
  content: "\e9d8";
}
.icon-document2:before {
  content: "\e9d9";
}
.icon-graduation-hat:before {
  content: "\e9da";
}
.icon-license:before {
  content: "\e9db";
}
.icon-license2:before {
  content: "\e9dc";
}
.icon-medal-empty:before {
  content: "\e9dd";
}
.icon-medal-first:before {
  content: "\e9de";
}
.icon-medal-second:before {
  content: "\e9df";
}
.icon-medal-third:before {
  content: "\e9e0";
}
.icon-podium:before {
  content: "\e9e1";
}
.icon-trophy:before {
  content: "\e9e2";
}
.icon-trophy2:before {
  content: "\e9e3";
}
.icon-music-note:before {
  content: "\e9e4";
}
.icon-music-note2:before {
  content: "\e9e5";
}
.icon-music-note3:before {
  content: "\e9e6";
}
.icon-playlist:before {
  content: "\e9e7";
}
.icon-playlist-add:before {
  content: "\e9e8";
}
.icon-guitar:before {
  content: "\e9e9";
}
.icon-trumpet:before {
  content: "\e9ea";
}
.icon-album:before {
  content: "\e9eb";
}
.icon-shuffle:before {
  content: "\e9ec";
}
.icon-repeat-one:before {
  content: "\e9ed";
}
.icon-repeat:before {
  content: "\e9ee";
}
.icon-headphones:before {
  content: "\e9ef";
}
.icon-headset:before {
  content: "\e9f0";
}
.icon-loudspeaker:before {
  content: "\e9f1";
}
.icon-equalizer:before {
  content: "\e9f2";
}
.icon-theater:before {
  content: "\e9f3";
}
.icon-3d-glasses:before {
  content: "\e9f4";
}
.icon-ticket:before {
  content: "\e9f5";
}
.icon-presentation:before {
  content: "\e9f6";
}
.icon-play:before {
  content: "\e9f7";
}
.icon-film-play:before {
  content: "\e9f8";
}
.icon-clapboard-play:before {
  content: "\e9f9";
}
.icon-media:before {
  content: "\e9fa";
}
.icon-film:before {
  content: "\e9fb";
}
.icon-film2:before {
  content: "\e9fc";
}
.icon-surveillance:before {
  content: "\e9fd";
}
.icon-surveillance2:before {
  content: "\e9fe";
}
.icon-camera:before {
  content: "\e9ff";
}
.icon-camera-crossed:before {
  content: "\ea00";
}
.icon-camera-play:before {
  content: "\ea01";
}
.icon-time-lapse:before {
  content: "\ea02";
}
.icon-record:before {
  content: "\ea03";
}
.icon-camera2:before {
  content: "\ea04";
}
.icon-camera-flip:before {
  content: "\ea05";
}
.icon-panorama:before {
  content: "\ea06";
}
.icon-time-lapse2:before {
  content: "\ea07";
}
.icon-shutter:before {
  content: "\ea08";
}
.icon-shutter2:before {
  content: "\ea09";
}
.icon-face-detection:before {
  content: "\ea0a";
}
.icon-flare:before {
  content: "\ea0b";
}
.icon-convex:before {
  content: "\ea0c";
}
.icon-concave:before {
  content: "\ea0d";
}
.icon-picture:before {
  content: "\ea0e";
}
.icon-picture2:before {
  content: "\ea0f";
}
.icon-picture3:before {
  content: "\ea10";
}
.icon-pictures:before {
  content: "\ea11";
}
.icon-book:before {
  content: "\ea12";
}
.icon-audio-book:before {
  content: "\ea13";
}
.icon-book2:before {
  content: "\ea14";
}
.icon-bookmark:before {
  content: "\ea15";
}
.icon-bookmark2:before {
  content: "\ea16";
}
.icon-label:before {
  content: "\ea17";
}
.icon-library:before {
  content: "\ea18";
}
.icon-library2:before {
  content: "\ea19";
}
.icon-contacts:before {
  content: "\ea1a";
}
.icon-profile:before {
  content: "\ea1b";
}
.icon-portrait:before {
  content: "\ea1c";
}
.icon-portrait2:before {
  content: "\ea1d";
}
.icon-user:before {
  content: "\ea1e";
}
.icon-user-plus:before {
  content: "\ea1f";
}
.icon-user-minus:before {
  content: "\ea20";
}
.icon-user-lock:before {
  content: "\ea21";
}
.icon-users:before {
  content: "\ea22";
}
.icon-users2:before {
  content: "\ea23";
}
.icon-users-plus:before {
  content: "\ea24";
}
.icon-users-minus:before {
  content: "\ea25";
}
.icon-group-work:before {
  content: "\ea26";
}
.icon-woman:before {
  content: "\ea27";
}
.icon-man:before {
  content: "\ea28";
}
.icon-baby:before {
  content: "\ea29";
}
.icon-baby2:before {
  content: "\ea2a";
}
.icon-baby3:before {
  content: "\ea2b";
}
.icon-baby-bottle:before {
  content: "\ea2c";
}
.icon-walk:before {
  content: "\ea2d";
}
.icon-hand-waving:before {
  content: "\ea2e";
}
.icon-jump:before {
  content: "\ea2f";
}
.icon-run:before {
  content: "\ea30";
}
.icon-woman2:before {
  content: "\ea31";
}
.icon-man2:before {
  content: "\ea32";
}
.icon-man-woman:before {
  content: "\ea33";
}
.icon-height:before {
  content: "\ea34";
}
.icon-weight:before {
  content: "\ea35";
}
.icon-scale:before {
  content: "\ea36";
}
.icon-button:before {
  content: "\ea37";
}
.icon-bow-tie:before {
  content: "\ea38";
}
.icon-tie:before {
  content: "\ea39";
}
.icon-socks:before {
  content: "\ea3a";
}
.icon-shoe:before {
  content: "\ea3b";
}
.icon-shoes:before {
  content: "\ea3c";
}
.icon-hat:before {
  content: "\ea3d";
}
.icon-pants:before {
  content: "\ea3e";
}
.icon-shorts:before {
  content: "\ea3f";
}
.icon-flip-flops:before {
  content: "\ea40";
}
.icon-shirt:before {
  content: "\ea41";
}
.icon-hanger:before {
  content: "\ea42";
}
.icon-laundry:before {
  content: "\ea43";
}
.icon-store:before {
  content: "\ea44";
}
.icon-haircut:before {
  content: "\ea45";
}
.icon-store-24:before {
  content: "\ea46";
}
.icon-barcode:before {
  content: "\ea47";
}
.icon-barcode2:before {
  content: "\ea48";
}
.icon-barcode3:before {
  content: "\ea49";
}
.icon-cashier:before {
  content: "\ea4a";
}
.icon-bag:before {
  content: "\ea4b";
}
.icon-bag2:before {
  content: "\ea4c";
}
.icon-cart:before {
  content: "\ea4d";
}
.icon-cart-empty:before {
  content: "\ea4e";
}
.icon-cart-full:before {
  content: "\ea4f";
}
.icon-cart-plus:before {
  content: "\ea50";
}
.icon-cart-plus2:before {
  content: "\ea51";
}
.icon-cart-add:before {
  content: "\ea52";
}
.icon-cart-remove:before {
  content: "\ea53";
}
.icon-cart-exchange:before {
  content: "\ea54";
}
.icon-tag:before {
  content: "\ea55";
}
.icon-tags:before {
  content: "\ea56";
}
.icon-receipt:before {
  content: "\ea57";
}
.icon-wallet:before {
  content: "\ea58";
}
.icon-credit-card:before {
  content: "\ea59";
}
.icon-cash-dollar:before {
  content: "\ea5a";
}
.icon-cash-euro:before {
  content: "\ea5b";
}
.icon-cash-pound:before {
  content: "\ea5c";
}
.icon-cash-yen:before {
  content: "\ea5d";
}
.icon-bag-dollar:before {
  content: "\ea5e";
}
.icon-bag-euro:before {
  content: "\ea5f";
}
.icon-bag-pound:before {
  content: "\ea60";
}
.icon-bag-yen:before {
  content: "\ea61";
}
.icon-coin-dollar:before {
  content: "\ea62";
}
.icon-coin-euro:before {
  content: "\ea63";
}
.icon-coin-pound:before {
  content: "\ea64";
}
.icon-coin-yen:before {
  content: "\ea65";
}
.icon-calculator:before {
  content: "\ea66";
}
.icon-calculator2:before {
  content: "\ea67";
}
.icon-abacus:before {
  content: "\ea68";
}
.icon-vault:before {
  content: "\ea69";
}
.icon-telephone:before {
  content: "\ea6a";
}
.icon-phone-lock:before {
  content: "\ea6b";
}
.icon-phone-wave:before {
  content: "\ea6c";
}
.icon-phone-pause:before {
  content: "\ea6d";
}
.icon-phone-outgoing:before {
  content: "\ea6e";
}
.icon-phone-incoming:before {
  content: "\ea6f";
}
.icon-phone-in-out:before {
  content: "\ea70";
}
.icon-phone-error:before {
  content: "\ea71";
}
.icon-phone-sip:before {
  content: "\ea72";
}
.icon-phone-plus:before {
  content: "\ea73";
}
.icon-phone-minus:before {
  content: "\ea74";
}
.icon-voicemail:before {
  content: "\ea75";
}
.icon-dial:before {
  content: "\ea76";
}
.icon-telephone2:before {
  content: "\ea77";
}
.icon-pushpin:before {
  content: "\ea78";
}
.icon-pushpin2:before {
  content: "\ea79";
}
.icon-map-marker:before {
  content: "\ea7a";
}
.icon-map-marker-user:before {
  content: "\ea7b";
}
.icon-map-marker-down:before {
  content: "\ea7c";
}
.icon-map-marker-check:before {
  content: "\ea7d";
}
.icon-map-marker-crossed:before {
  content: "\ea7e";
}
.icon-radar:before {
  content: "\ea7f";
}
.icon-compass2:before {
  content: "\ea80";
}
.icon-map:before {
  content: "\ea81";
}
.icon-map2:before {
  content: "\ea82";
}
.icon-location:before {
  content: "\ea83";
}
.icon-road-sign:before {
  content: "\ea84";
}
.icon-calendar-empty:before {
  content: "\ea85";
}
.icon-calendar-check:before {
  content: "\ea86";
}
.icon-calendar-cross:before {
  content: "\ea87";
}
.icon-calendar-31:before {
  content: "\ea88";
}
.icon-calendar-full:before {
  content: "\ea89";
}
.icon-calendar-insert:before {
  content: "\ea8a";
}
.icon-calendar-text:before {
  content: "\ea8b";
}
.icon-calendar-user:before {
  content: "\ea8c";
}
.icon-mouse:before {
  content: "\ea8d";
}
.icon-mouse-left:before {
  content: "\ea8e";
}
.icon-mouse-right:before {
  content: "\ea8f";
}
.icon-mouse-both:before {
  content: "\ea90";
}
.icon-keyboard:before {
  content: "\ea91";
}
.icon-keyboard-up:before {
  content: "\ea92";
}
.icon-keyboard-down:before {
  content: "\ea93";
}
.icon-delete:before {
  content: "\ea94";
}
.icon-spell-check:before {
  content: "\ea95";
}
.icon-escape:before {
  content: "\ea96";
}
.icon-enter2:before {
  content: "\ea97";
}
.icon-screen:before {
  content: "\ea98";
}
.icon-aspect-ratio:before {
  content: "\ea99";
}
.icon-signal:before {
  content: "\ea9a";
}
.icon-signal-lock:before {
  content: "\ea9b";
}
.icon-signal-80:before {
  content: "\ea9c";
}
.icon-signal-60:before {
  content: "\ea9d";
}
.icon-signal-40:before {
  content: "\ea9e";
}
.icon-signal-20:before {
  content: "\ea9f";
}
.icon-signal-0:before {
  content: "\eaa0";
}
.icon-signal-blocked:before {
  content: "\eaa1";
}
.icon-sim:before {
  content: "\eaa2";
}
.icon-flash-memory:before {
  content: "\eaa3";
}
.icon-usb-drive:before {
  content: "\eaa4";
}
.icon-phone:before {
  content: "\eaa5";
}
.icon-smartphone:before {
  content: "\eaa6";
}
.icon-smartphone-notification:before {
  content: "\eaa7";
}
.icon-smartphone-vibration:before {
  content: "\eaa8";
}
.icon-smartphone-embed:before {
  content: "\eaa9";
}
.icon-smartphone-waves:before {
  content: "\eaaa";
}
.icon-tablet:before {
  content: "\eaab";
}
.icon-tablet2:before {
  content: "\eaac";
}
.icon-laptop:before {
  content: "\eaad";
}
.icon-laptop-phone:before {
  content: "\eaae";
}
.icon-desktop:before {
  content: "\eaaf";
}
.icon-launch:before {
  content: "\eab0";
}
.icon-new-tab:before {
  content: "\eab1";
}
.icon-window:before {
  content: "\eab2";
}
.icon-cable:before {
  content: "\eab3";
}
.icon-cable2:before {
  content: "\eab4";
}
.icon-tv:before {
  content: "\eab5";
}
.icon-radio:before {
  content: "\eab6";
}
.icon-remote-control:before {
  content: "\eab7";
}
.icon-power-switch:before {
  content: "\eab8";
}
.icon-power:before {
  content: "\eab9";
}
.icon-power-crossed:before {
  content: "\eaba";
}
.icon-flash-auto:before {
  content: "\eabb";
}
.icon-lamp:before {
  content: "\eabc";
}
.icon-flashlight:before {
  content: "\eabd";
}
.icon-lampshade:before {
  content: "\eabe";
}
.icon-cord:before {
  content: "\eabf";
}
.icon-outlet:before {
  content: "\eac0";
}
.icon-battery-power:before {
  content: "\eac1";
}
.icon-battery-empty:before {
  content: "\eac2";
}
.icon-battery-alert:before {
  content: "\eac3";
}
.icon-battery-error:before {
  content: "\eac4";
}
.icon-battery-low1:before {
  content: "\eac5";
}
.icon-battery-low2:before {
  content: "\eac6";
}
.icon-battery-low3:before {
  content: "\eac7";
}
.icon-battery-mid1:before {
  content: "\eac8";
}
.icon-battery-mid2:before {
  content: "\eac9";
}
.icon-battery-mid3:before {
  content: "\eaca";
}
.icon-battery-full:before {
  content: "\eacb";
}
.icon-battery-charging:before {
  content: "\eacc";
}
.icon-battery-charging2:before {
  content: "\eacd";
}
.icon-battery-charging3:before {
  content: "\eace";
}
.icon-battery-charging4:before {
  content: "\eacf";
}
.icon-battery-charging5:before {
  content: "\ead0";
}
.icon-battery-charging6:before {
  content: "\ead1";
}
.icon-battery-charging7:before {
  content: "\ead2";
}
.icon-chip:before {
  content: "\ead3";
}
.icon-chip-x64:before {
  content: "\ead4";
}
.icon-chip-x86:before {
  content: "\ead5";
}
.icon-bubble:before {
  content: "\ead6";
}
.icon-bubbles:before {
  content: "\ead7";
}
.icon-bubble-dots:before {
  content: "\ead8";
}
.icon-bubble-alert:before {
  content: "\ead9";
}
.icon-bubble-question:before {
  content: "\eada";
}
.icon-bubble-text:before {
  content: "\eadb";
}
.icon-bubble-pencil:before {
  content: "\eadc";
}
.icon-bubble-picture:before {
  content: "\eadd";
}
.icon-bubble-video:before {
  content: "\eade";
}
.icon-bubble-user:before {
  content: "\eadf";
}
.icon-bubble-quote:before {
  content: "\eae0";
}
.icon-bubble-heart:before {
  content: "\eae1";
}
.icon-bubble-emoticon:before {
  content: "\eae2";
}
.icon-bubble-attachment:before {
  content: "\eae3";
}
.icon-phone-bubble:before {
  content: "\eae4";
}
.icon-quote-open:before {
  content: "\eae5";
}
.icon-quote-close:before {
  content: "\eae6";
}
.icon-dna:before {
  content: "\eae7";
}
.icon-heart-pulse:before {
  content: "\eae8";
}
.icon-pulse:before {
  content: "\eae9";
}
.icon-syringe:before {
  content: "\eaea";
}
.icon-pills:before {
  content: "\eaeb";
}
.icon-first-aid:before {
  content: "\eaec";
}
.icon-lifebuoy:before {
  content: "\eaed";
}
.icon-bandage:before {
  content: "\eaee";
}
.icon-bandages:before {
  content: "\eaef";
}
.icon-thermometer:before {
  content: "\eaf0";
}
.icon-microscope:before {
  content: "\eaf1";
}
.icon-brain:before {
  content: "\eaf2";
}
.icon-beaker:before {
  content: "\eaf3";
}
.icon-skull:before {
  content: "\eaf4";
}
.icon-bone:before {
  content: "\eaf5";
}
.icon-construction:before {
  content: "\eaf6";
}
.icon-construction-cone:before {
  content: "\eaf7";
}
.icon-pie-chart:before {
  content: "\eaf8";
}
.icon-pie-chart2:before {
  content: "\eaf9";
}
.icon-graph:before {
  content: "\eafa";
}
.icon-chart-growth:before {
  content: "\eafb";
}
.icon-chart-bars:before {
  content: "\eafc";
}
.icon-chart-settings:before {
  content: "\eafd";
}
.icon-cake:before {
  content: "\eafe";
}
.icon-gift:before {
  content: "\eaff";
}
.icon-balloon:before {
  content: "\eb00";
}
.icon-rank:before {
  content: "\eb01";
}
.icon-rank2:before {
  content: "\eb02";
}
.icon-rank3:before {
  content: "\eb03";
}
.icon-crown:before {
  content: "\eb04";
}
.icon-lotus:before {
  content: "\eb05";
}
.icon-diamond:before {
  content: "\eb06";
}
.icon-diamond2:before {
  content: "\eb07";
}
.icon-diamond3:before {
  content: "\eb08";
}
.icon-diamond4:before {
  content: "\eb09";
}
.icon-linearicons:before {
  content: "\eb0a";
}
.icon-teacup:before {
  content: "\eb0b";
}
.icon-teapot:before {
  content: "\eb0c";
}
.icon-glass:before {
  content: "\eb0d";
}
.icon-bottle2:before {
  content: "\eb0e";
}
.icon-glass-cocktail:before {
  content: "\eb0f";
}
.icon-glass2:before {
  content: "\eb10";
}
.icon-dinner:before {
  content: "\eb11";
}
.icon-dinner2:before {
  content: "\eb12";
}
.icon-chef:before {
  content: "\eb13";
}
.icon-scale2:before {
  content: "\eb14";
}
.icon-egg:before {
  content: "\eb15";
}
.icon-egg2:before {
  content: "\eb16";
}
.icon-eggs:before {
  content: "\eb17";
}
.icon-platter:before {
  content: "\eb18";
}
.icon-steak:before {
  content: "\eb19";
}
.icon-hamburger:before {
  content: "\eb1a";
}
.icon-hotdog:before {
  content: "\eb1b";
}
.icon-pizza:before {
  content: "\eb1c";
}
.icon-sausage:before {
  content: "\eb1d";
}
.icon-chicken:before {
  content: "\eb1e";
}
.icon-fish:before {
  content: "\eb1f";
}
.icon-carrot:before {
  content: "\eb20";
}
.icon-cheese:before {
  content: "\eb21";
}
.icon-bread:before {
  content: "\eb22";
}
.icon-ice-cream:before {
  content: "\eb23";
}
.icon-ice-cream2:before {
  content: "\eb24";
}
.icon-candy:before {
  content: "\eb25";
}
.icon-lollipop:before {
  content: "\eb26";
}
.icon-coffee-bean:before {
  content: "\eb27";
}
.icon-coffee-cup:before {
  content: "\eb28";
}
.icon-cherry:before {
  content: "\eb29";
}
.icon-grapes:before {
  content: "\eb2a";
}
.icon-citrus:before {
  content: "\eb2b";
}
.icon-apple:before {
  content: "\eb2c";
}
.icon-leaf:before {
  content: "\eb2d";
}
.icon-landscape:before {
  content: "\eb2e";
}
.icon-pine-tree:before {
  content: "\eb2f";
}
.icon-tree:before {
  content: "\eb30";
}
.icon-cactus:before {
  content: "\eb31";
}
.icon-paw:before {
  content: "\eb32";
}
.icon-footprint:before {
  content: "\eb33";
}
.icon-speed-slow:before {
  content: "\eb34";
}
.icon-speed-medium:before {
  content: "\eb35";
}
.icon-speed-fast:before {
  content: "\eb36";
}
.icon-rocket:before {
  content: "\eb37";
}
.icon-hammer2:before {
  content: "\eb38";
}
.icon-balance:before {
  content: "\eb39";
}
.icon-briefcase:before {
  content: "\eb3a";
}
.icon-luggage-weight:before {
  content: "\eb3b";
}
.icon-dolly:before {
  content: "\eb3c";
}
.icon-plane:before {
  content: "\eb3d";
}
.icon-plane-crossed:before {
  content: "\eb3e";
}
.icon-helicopter:before {
  content: "\eb3f";
}
.icon-traffic-lights:before {
  content: "\eb40";
}
.icon-siren:before {
  content: "\eb41";
}
.icon-road:before {
  content: "\eb42";
}
.icon-engine:before {
  content: "\eb43";
}
.icon-oil-pressure:before {
  content: "\eb44";
}
.icon-coolant-temperature:before {
  content: "\eb45";
}
.icon-car-battery:before {
  content: "\eb46";
}
.icon-gas:before {
  content: "\eb47";
}
.icon-gallon:before {
  content: "\eb48";
}
.icon-transmission:before {
  content: "\eb49";
}
.icon-car:before {
  content: "\eb4a";
}
.icon-car-wash:before {
  content: "\eb4b";
}
.icon-car-wash2:before {
  content: "\eb4c";
}
.icon-bus:before {
  content: "\eb4d";
}
.icon-bus2:before {
  content: "\eb4e";
}
.icon-car2:before {
  content: "\eb4f";
}
.icon-parking:before {
  content: "\eb50";
}
.icon-car-lock:before {
  content: "\eb51";
}
.icon-taxi:before {
  content: "\eb52";
}
.icon-car-siren:before {
  content: "\eb53";
}
.icon-car-wash3:before {
  content: "\eb54";
}
.icon-car-wash4:before {
  content: "\eb55";
}
.icon-ambulance:before {
  content: "\eb56";
}
.icon-truck:before {
  content: "\eb57";
}
.icon-trailer:before {
  content: "\eb58";
}
.icon-scale-truck:before {
  content: "\eb59";
}
.icon-train:before {
  content: "\eb5a";
}
.icon-ship:before {
  content: "\eb5b";
}
.icon-ship2:before {
  content: "\eb5c";
}
.icon-anchor:before {
  content: "\eb5d";
}
.icon-boat:before {
  content: "\eb5e";
}
.icon-bicycle:before {
  content: "\eb5f";
}
.icon-bicycle2:before {
  content: "\eb60";
}
.icon-dumbbell:before {
  content: "\eb61";
}
.icon-bench-press:before {
  content: "\eb62";
}
.icon-swim:before {
  content: "\eb63";
}
.icon-football:before {
  content: "\eb64";
}
.icon-baseball-bat:before {
  content: "\eb65";
}
.icon-baseball:before {
  content: "\eb66";
}
.icon-tennis:before {
  content: "\eb67";
}
.icon-tennis2:before {
  content: "\eb68";
}
.icon-ping-pong:before {
  content: "\eb69";
}
.icon-hockey:before {
  content: "\eb6a";
}
.icon-8ball:before {
  content: "\eb6b";
}
.icon-bowling:before {
  content: "\eb6c";
}
.icon-bowling-pins:before {
  content: "\eb6d";
}
.icon-golf:before {
  content: "\eb6e";
}
.icon-golf2:before {
  content: "\eb6f";
}
.icon-archery:before {
  content: "\eb70";
}
.icon-slingshot:before {
  content: "\eb71";
}
.icon-soccer:before {
  content: "\eb72";
}
.icon-basketball:before {
  content: "\eb73";
}
.icon-cube:before {
  content: "\eb74";
}
.icon-3d-rotate:before {
  content: "\eb75";
}
.icon-puzzle:before {
  content: "\eb76";
}
.icon-glasses:before {
  content: "\eb77";
}
.icon-glasses2:before {
  content: "\eb78";
}
.icon-accessibility:before {
  content: "\eb79";
}
.icon-wheelchair:before {
  content: "\eb7a";
}
.icon-wall:before {
  content: "\eb7b";
}
.icon-fence:before {
  content: "\eb7c";
}
.icon-wall2:before {
  content: "\eb7d";
}
.icon-icons:before {
  content: "\eb7e";
}
.icon-resize-handle:before {
  content: "\eb7f";
}
.icon-icons2:before {
  content: "\eb80";
}
.icon-select:before {
  content: "\eb81";
}
.icon-select2:before {
  content: "\eb82";
}
.icon-site-map:before {
  content: "\eb83";
}
.icon-earth:before {
  content: "\eb84";
}
.icon-earth-lock:before {
  content: "\eb85";
}
.icon-network:before {
  content: "\eb86";
}
.icon-network-lock:before {
  content: "\eb87";
}
.icon-planet:before {
  content: "\eb88";
}
.icon-happy:before {
  content: "\eb89";
}
.icon-smile:before {
  content: "\eb8a";
}
.icon-grin:before {
  content: "\eb8b";
}
.icon-tongue:before {
  content: "\eb8c";
}
.icon-sad:before {
  content: "\eb8d";
}
.icon-wink:before {
  content: "\eb8e";
}
.icon-dream:before {
  content: "\eb8f";
}
.icon-shocked:before {
  content: "\eb90";
}
.icon-shocked2:before {
  content: "\eb91";
}
.icon-tongue2:before {
  content: "\eb92";
}
.icon-neutral:before {
  content: "\eb93";
}
.icon-happy-grin:before {
  content: "\eb94";
}
.icon-cool:before {
  content: "\eb95";
}
.icon-mad:before {
  content: "\eb96";
}
.icon-grin-evil:before {
  content: "\eb97";
}
.icon-evil:before {
  content: "\eb98";
}
.icon-wow:before {
  content: "\eb99";
}
.icon-annoyed:before {
  content: "\eb9a";
}
.icon-wondering:before {
  content: "\eb9b";
}
.icon-confused:before {
  content: "\eb9c";
}
.icon-zipped:before {
  content: "\eb9d";
}
.icon-grumpy:before {
  content: "\eb9e";
}
.icon-mustache:before {
  content: "\eb9f";
}
.icon-tombstone-hipster:before {
  content: "\eba0";
}
.icon-tombstone:before {
  content: "\eba1";
}
.icon-ghost:before {
  content: "\eba2";
}
.icon-ghost-hipster:before {
  content: "\eba3";
}
.icon-halloween:before {
  content: "\eba4";
}
.icon-christmas:before {
  content: "\eba5";
}
.icon-easter-egg:before {
  content: "\eba6";
}
.icon-mustache2:before {
  content: "\eba7";
}
.icon-mustache-glasses:before {
  content: "\eba8";
}
.icon-pipe:before {
  content: "\eba9";
}
.icon-alarm:before {
  content: "\ebaa";
}
.icon-alarm-add:before {
  content: "\ebab";
}
.icon-alarm-snooze:before {
  content: "\ebac";
}
.icon-alarm-ringing:before {
  content: "\ebad";
}
.icon-bullhorn:before {
  content: "\ebae";
}
.icon-hearing:before {
  content: "\ebaf";
}
.icon-volume-high:before {
  content: "\ebb0";
}
.icon-volume-medium:before {
  content: "\ebb1";
}
.icon-volume-low:before {
  content: "\ebb2";
}
.icon-volume:before {
  content: "\ebb3";
}
.icon-mute:before {
  content: "\ebb4";
}
.icon-lan:before {
  content: "\ebb5";
}
.icon-lan2:before {
  content: "\ebb6";
}
.icon-wifi:before {
  content: "\ebb7";
}
.icon-wifi-lock:before {
  content: "\ebb8";
}
.icon-wifi-blocked:before {
  content: "\ebb9";
}
.icon-wifi-mid:before {
  content: "\ebba";
}
.icon-wifi-low:before {
  content: "\ebbb";
}
.icon-wifi-low2:before {
  content: "\ebbc";
}
.icon-wifi-alert:before {
  content: "\ebbd";
}
.icon-wifi-alert-mid:before {
  content: "\ebbe";
}
.icon-wifi-alert-low:before {
  content: "\ebbf";
}
.icon-wifi-alert-low2:before {
  content: "\ebc0";
}
.icon-stream:before {
  content: "\ebc1";
}
.icon-stream-check:before {
  content: "\ebc2";
}
.icon-stream-error:before {
  content: "\ebc3";
}
.icon-stream-alert:before {
  content: "\ebc4";
}
.icon-communication:before {
  content: "\ebc5";
}
.icon-communication-crossed:before {
  content: "\ebc6";
}
.icon-broadcast:before {
  content: "\ebc7";
}
.icon-antenna:before {
  content: "\ebc8";
}
.icon-satellite:before {
  content: "\ebc9";
}
.icon-satellite2:before {
  content: "\ebca";
}
.icon-mic:before {
  content: "\ebcb";
}
.icon-mic-mute:before {
  content: "\ebcc";
}
.icon-mic2:before {
  content: "\ebcd";
}
.icon-spotlights:before {
  content: "\ebce";
}
.icon-hourglass:before {
  content: "\ebcf";
}
.icon-loading:before {
  content: "\ebd0";
}
.icon-loading2:before {
  content: "\ebd1";
}
.icon-loading3:before {
  content: "\ebd2";
}
.icon-refresh:before {
  content: "\ebd3";
}
.icon-refresh2:before {
  content: "\ebd4";
}
.icon-undo:before {
  content: "\ebd5";
}
.icon-redo:before {
  content: "\ebd6";
}
.icon-jump2:before {
  content: "\ebd7";
}
.icon-undo2:before {
  content: "\ebd8";
}
.icon-redo2:before {
  content: "\ebd9";
}
.icon-sync:before {
  content: "\ebda";
}
.icon-repeat-one2:before {
  content: "\ebdb";
}
.icon-sync-crossed:before {
  content: "\ebdc";
}
.icon-sync2:before {
  content: "\ebdd";
}
.icon-repeat-one3:before {
  content: "\ebde";
}
.icon-sync-crossed2:before {
  content: "\ebdf";
}
.icon-return:before {
  content: "\ebe0";
}
.icon-return2:before {
  content: "\ebe1";
}
.icon-refund:before {
  content: "\ebe2";
}
.icon-history:before {
  content: "\ebe3";
}
.icon-history2:before {
  content: "\ebe4";
}
.icon-self-timer:before {
  content: "\ebe5";
}
.icon-clock:before {
  content: "\ebe6";
}
.icon-clock2:before {
  content: "\ebe7";
}
.icon-clock3:before {
  content: "\ebe8";
}
.icon-watch:before {
  content: "\ebe9";
}
.icon-alarm2:before {
  content: "\ebea";
}
.icon-alarm-add2:before {
  content: "\ebeb";
}
.icon-alarm-remove:before {
  content: "\ebec";
}
.icon-alarm-check:before {
  content: "\ebed";
}
.icon-alarm-error:before {
  content: "\ebee";
}
.icon-timer:before {
  content: "\ebef";
}
.icon-timer-crossed:before {
  content: "\ebf0";
}
.icon-timer2:before {
  content: "\ebf1";
}
.icon-timer-crossed2:before {
  content: "\ebf2";
}
.icon-download:before {
  content: "\ebf3";
}
.icon-upload:before {
  content: "\ebf4";
}
.icon-download2:before {
  content: "\ebf5";
}
.icon-upload2:before {
  content: "\ebf6";
}
.icon-enter-up:before {
  content: "\ebf7";
}
.icon-enter-down:before {
  content: "\ebf8";
}
.icon-enter-left:before {
  content: "\ebf9";
}
.icon-enter-right:before {
  content: "\ebfa";
}
.icon-exit-up:before {
  content: "\ebfb";
}
.icon-exit-down:before {
  content: "\ebfc";
}
.icon-exit-left:before {
  content: "\ebfd";
}
.icon-exit-right:before {
  content: "\ebfe";
}
.icon-enter-up2:before {
  content: "\ebff";
}
.icon-enter-down2:before {
  content: "\ec00";
}
.icon-enter-vertical:before {
  content: "\ec01";
}
.icon-enter-left2:before {
  content: "\ec02";
}
.icon-enter-right2:before {
  content: "\ec03";
}
.icon-enter-horizontal:before {
  content: "\ec04";
}
.icon-exit-up2:before {
  content: "\ec05";
}
.icon-exit-down2:before {
  content: "\ec06";
}
.icon-exit-left2:before {
  content: "\ec07";
}
.icon-exit-right2:before {
  content: "\ec08";
}
.icon-cli:before {
  content: "\ec09";
}
.icon-bug:before {
  content: "\ec0a";
}
.icon-code:before {
  content: "\ec0b";
}
.icon-file-code:before {
  content: "\ec0c";
}
.icon-file-image:before {
  content: "\ec0d";
}
.icon-file-zip:before {
  content: "\ec0e";
}
.icon-file-audio:before {
  content: "\ec0f";
}
.icon-file-video:before {
  content: "\ec10";
}
.icon-file-preview:before {
  content: "\ec11";
}
.icon-file-charts:before {
  content: "\ec12";
}
.icon-file-stats:before {
  content: "\ec13";
}
.icon-file-spreadsheet:before {
  content: "\ec14";
}
.icon-link:before {
  content: "\ec15";
}
.icon-unlink:before {
  content: "\ec16";
}
.icon-link2:before {
  content: "\ec17";
}
.icon-unlink2:before {
  content: "\ec18";
}
.icon-thumbs-up:before {
  content: "\ec19";
}
.icon-thumbs-down:before {
  content: "\ec1a";
}
.icon-thumbs-up2:before {
  content: "\ec1b";
}
.icon-thumbs-down2:before {
  content: "\ec1c";
}
.icon-thumbs-up3:before {
  content: "\ec1d";
}
.icon-thumbs-down3:before {
  content: "\ec1e";
}
.icon-share:before {
  content: "\ec1f";
}
.icon-share2:before {
  content: "\ec20";
}
.icon-share3:before {
  content: "\ec21";
}
.icon-magnifier:before {
  content: "\ec22";
}
.icon-file-search:before {
  content: "\ec23";
}
.icon-find-replace:before {
  content: "\ec24";
}
.icon-zoom-in:before {
  content: "\ec25";
}
.icon-zoom-out:before {
  content: "\ec26";
}
.icon-loupe:before {
  content: "\ec27";
}
.icon-loupe-zoom-in:before {
  content: "\ec28";
}
.icon-loupe-zoom-out:before {
  content: "\ec29";
}
.icon-cross:before {
  content: "\ec2a";
}
.icon-menu:before {
  content: "\ec2b";
}
.icon-list:before {
  content: "\ec2c";
}
.icon-list2:before {
  content: "\ec2d";
}
.icon-list3:before {
  content: "\ec2e";
}
.icon-menu2:before {
  content: "\ec2f";
}
.icon-list4:before {
  content: "\ec30";
}
.icon-menu3:before {
  content: "\ec31";
}
.icon-exclamation:before {
  content: "\ec32";
}
.icon-question:before {
  content: "\ec33";
}
.icon-check:before {
  content: "\ec34";
}
.icon-cross2:before {
  content: "\ec35";
}
.icon-plus:before {
  content: "\ec36";
}
.icon-minus:before {
  content: "\ec37";
}
.icon-percent:before {
  content: "\ec38";
}
.icon-chevron-up:before {
  content: "\ec39";
}
.icon-chevron-down:before {
  content: "\ec3a";
}
.icon-chevron-left:before {
  content: "\ec3b";
}
.icon-chevron-right:before {
  content: "\ec3c";
}
.icon-chevrons-expand-vertical:before {
  content: "\ec3d";
}
.icon-chevrons-expand-horizontal:before {
  content: "\ec3e";
}
.icon-chevrons-contract-vertical:before {
  content: "\ec3f";
}
.icon-chevrons-contract-horizontal:before {
  content: "\ec40";
}
.icon-arrow-up:before {
  content: "\ec41";
}
.icon-arrow-down:before {
  content: "\ec42";
}
.icon-arrow-left:before {
  content: "\ec43";
}
.icon-arrow-right:before {
  content: "\ec44";
}
.icon-arrow-up-right:before {
  content: "\ec45";
}
.icon-arrows-merge:before {
  content: "\ec46";
}
.icon-arrows-split:before {
  content: "\ec47";
}
.icon-arrow-divert:before {
  content: "\ec48";
}
.icon-arrow-return:before {
  content: "\ec49";
}
.icon-expand:before {
  content: "\ec4a";
}
.icon-contract:before {
  content: "\ec4b";
}
.icon-expand2:before {
  content: "\ec4c";
}
.icon-contract2:before {
  content: "\ec4d";
}
.icon-move:before {
  content: "\ec4e";
}
.icon-tab:before {
  content: "\ec4f";
}
.icon-arrow-wave:before {
  content: "\ec50";
}
.icon-expand3:before {
  content: "\ec51";
}
.icon-expand4:before {
  content: "\ec52";
}
.icon-contract3:before {
  content: "\ec53";
}
.icon-notification:before {
  content: "\ec54";
}
.icon-warning:before {
  content: "\ec55";
}
.icon-notification-circle:before {
  content: "\ec56";
}
.icon-question-circle:before {
  content: "\ec57";
}
.icon-menu-circle:before {
  content: "\ec58";
}
.icon-checkmark-circle:before {
  content: "\ec59";
}
.icon-cross-circle:before {
  content: "\ec5a";
}
.icon-plus-circle:before {
  content: "\ec5b";
}
.icon-circle-minus:before {
  content: "\ec5c";
}
.icon-percent-circle:before {
  content: "\ec5d";
}
.icon-arrow-up-circle:before {
  content: "\ec5e";
}
.icon-arrow-down-circle:before {
  content: "\ec5f";
}
.icon-arrow-left-circle:before {
  content: "\ec60";
}
.icon-arrow-right-circle:before {
  content: "\ec61";
}
.icon-chevron-up-circle:before {
  content: "\ec62";
}
.icon-chevron-down-circle:before {
  content: "\ec63";
}
.icon-chevron-left-circle:before {
  content: "\ec64";
}
.icon-chevron-right-circle:before {
  content: "\ec65";
}
.icon-backward-circle:before {
  content: "\ec66";
}
.icon-first-circle:before {
  content: "\ec67";
}
.icon-previous-circle:before {
  content: "\ec68";
}
.icon-stop-circle:before {
  content: "\ec69";
}
.icon-play-circle:before {
  content: "\ec6a";
}
.icon-pause-circle:before {
  content: "\ec6b";
}
.icon-next-circle:before {
  content: "\ec6c";
}
.icon-last-circle:before {
  content: "\ec6d";
}
.icon-forward-circle:before {
  content: "\ec6e";
}
.icon-eject-circle:before {
  content: "\ec6f";
}
.icon-crop:before {
  content: "\ec70";
}
.icon-frame-expand:before {
  content: "\ec71";
}
.icon-frame-contract:before {
  content: "\ec72";
}
.icon-focus:before {
  content: "\ec73";
}
.icon-transform:before {
  content: "\ec74";
}
.icon-grid:before {
  content: "\ec75";
}
.icon-grid-crossed:before {
  content: "\ec76";
}
.icon-layers:before {
  content: "\ec77";
}
.icon-layers-crossed:before {
  content: "\ec78";
}
.icon-toggle:before {
  content: "\ec79";
}
.icon-rulers:before {
  content: "\ec7a";
}
.icon-ruler:before {
  content: "\ec7b";
}
.icon-funnel:before {
  content: "\ec7c";
}
.icon-flip-horizontal:before {
  content: "\ec7d";
}
.icon-flip-vertical:before {
  content: "\ec7e";
}
.icon-flip-horizontal2:before {
  content: "\ec7f";
}
.icon-flip-vertical2:before {
  content: "\ec80";
}
.icon-angle:before {
  content: "\ec81";
}
.icon-angle2:before {
  content: "\ec82";
}
.icon-subtract:before {
  content: "\ec83";
}
.icon-combine:before {
  content: "\ec84";
}
.icon-intersect:before {
  content: "\ec85";
}
.icon-exclude:before {
  content: "\ec86";
}
.icon-align-center-vertical:before {
  content: "\ec87";
}
.icon-align-right:before {
  content: "\ec88";
}
.icon-align-bottom:before {
  content: "\ec89";
}
.icon-align-left:before {
  content: "\ec8a";
}
.icon-align-center-horizontal:before {
  content: "\ec8b";
}
.icon-align-top:before {
  content: "\ec8c";
}
.icon-square:before {
  content: "\ec8d";
}
.icon-plus-square:before {
  content: "\ec8e";
}
.icon-minus-square:before {
  content: "\ec8f";
}
.icon-percent-square:before {
  content: "\ec90";
}
.icon-arrow-up-square:before {
  content: "\ec91";
}
.icon-arrow-down-square:before {
  content: "\ec92";
}
.icon-arrow-left-square:before {
  content: "\ec93";
}
.icon-arrow-right-square:before {
  content: "\ec94";
}
.icon-chevron-up-square:before {
  content: "\ec95";
}
.icon-chevron-down-square:before {
  content: "\ec96";
}
.icon-chevron-left-square:before {
  content: "\ec97";
}
.icon-chevron-right-square:before {
  content: "\ec98";
}
.icon-check-square:before {
  content: "\ec99";
}
.icon-cross-square:before {
  content: "\ec9a";
}
.icon-menu-square:before {
  content: "\ec9b";
}
.icon-prohibited:before {
  content: "\ec9c";
}
.icon-circle:before {
  content: "\ec9d";
}
.icon-radio-button:before {
  content: "\ec9e";
}
.icon-ligature:before {
  content: "\ec9f";
}
.icon-text-format:before {
  content: "\eca0";
}
.icon-text-format-remove:before {
  content: "\eca1";
}
.icon-text-size:before {
  content: "\eca2";
}
.icon-bold:before {
  content: "\eca3";
}
.icon-italic:before {
  content: "\eca4";
}
.icon-underline:before {
  content: "\eca5";
}
.icon-strikethrough:before {
  content: "\eca6";
}
.icon-highlight:before {
  content: "\eca7";
}
.icon-text-align-left:before {
  content: "\eca8";
}
.icon-text-align-center:before {
  content: "\eca9";
}
.icon-text-align-right:before {
  content: "\ecaa";
}
.icon-text-align-justify:before {
  content: "\ecab";
}
.icon-line-spacing:before {
  content: "\ecac";
}
.icon-indent-increase:before {
  content: "\ecad";
}
.icon-indent-decrease:before {
  content: "\ecae";
}
.icon-text-wrap:before {
  content: "\ecaf";
}
.icon-pilcrow:before {
  content: "\ecb0";
}
.icon-direction-ltr:before {
  content: "\ecb1";
}
.icon-direction-rtl:before {
  content: "\ecb2";
}
.icon-page-break:before {
  content: "\ecb3";
}
.icon-page-break2:before {
  content: "\ecb4";
}
.icon-sort-alpha-asc:before {
  content: "\ecb5";
}
.icon-sort-alpha-desc:before {
  content: "\ecb6";
}
.icon-sort-numeric-asc:before {
  content: "\ecb7";
}
.icon-sort-numeric-desc:before {
  content: "\ecb8";
}
.icon-sort-amount-asc:before {
  content: "\ecb9";
}
.icon-sort-amount-desc:before {
  content: "\ecba";
}
.icon-sort-time-asc:before {
  content: "\ecbb";
}
.icon-sort-time-desc:before {
  content: "\ecbc";
}
.icon-sigma:before {
  content: "\ecbd";
}
.icon-pencil-line:before {
  content: "\ecbe";
}
.icon-hand:before {
  content: "\ecbf";
}
.icon-pointer-up:before {
  content: "\ecc0";
}
.icon-pointer-right:before {
  content: "\ecc1";
}
.icon-pointer-down:before {
  content: "\ecc2";
}
.icon-pointer-left:before {
  content: "\ecc3";
}
.icon-finger-tap:before {
  content: "\ecc4";
}
.icon-fingers-tap:before {
  content: "\ecc5";
}
.icon-reminder:before {
  content: "\ecc6";
}
.icon-fingers-crossed:before {
  content: "\ecc7";
}
.icon-fingers-victory:before {
  content: "\ecc8";
}
.icon-gesture-zoom:before {
  content: "\ecc9";
}
.icon-gesture-pinch:before {
  content: "\ecca";
}
.icon-fingers-scroll-horizontal:before {
  content: "\eccb";
}
.icon-fingers-scroll-vertical:before {
  content: "\eccc";
}
.icon-fingers-scroll-left:before {
  content: "\eccd";
}
.icon-fingers-scroll-right:before {
  content: "\ecce";
}
.icon-hand2:before {
  content: "\eccf";
}
.icon-pointer-up2:before {
  content: "\ecd0";
}
.icon-pointer-right2:before {
  content: "\ecd1";
}
.icon-pointer-down2:before {
  content: "\ecd2";
}
.icon-pointer-left2:before {
  content: "\ecd3";
}
.icon-finger-tap2:before {
  content: "\ecd4";
}
.icon-fingers-tap2:before {
  content: "\ecd5";
}
.icon-reminder2:before {
  content: "\ecd6";
}
.icon-gesture-zoom2:before {
  content: "\ecd7";
}
.icon-gesture-pinch2:before {
  content: "\ecd8";
}
.icon-fingers-scroll-horizontal2:before {
  content: "\ecd9";
}
.icon-fingers-scroll-vertical2:before {
  content: "\ecda";
}
.icon-fingers-scroll-left2:before {
  content: "\ecdb";
}
.icon-fingers-scroll-right2:before {
  content: "\ecdc";
}
.icon-fingers-scroll-vertical3:before {
  content: "\ecdd";
}
.icon-border-style:before {
  content: "\ecde";
}
.icon-border-all:before {
  content: "\ecdf";
}
.icon-border-outer:before {
  content: "\ece0";
}
.icon-border-inner:before {
  content: "\ece1";
}
.icon-border-top:before {
  content: "\ece2";
}
.icon-border-horizontal:before {
  content: "\ece3";
}
.icon-border-bottom:before {
  content: "\ece4";
}
.icon-border-left:before {
  content: "\ece5";
}
.icon-border-vertical:before {
  content: "\ece6";
}
.icon-border-right:before {
  content: "\ece7";
}
.icon-border-none:before {
  content: "\ece8";
}
.icon-ellipsis:before {
  content: "\ece9";
}
