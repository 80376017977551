.template-home {
  
  section {
    &#top {
      .text-animation {
        font-family: $headings-font-family;
        font-weight: normal;
        font-size: 80px;
        line-height: 1;

        #typewriter {
          font-weight: bold;
        }

        @include media-breakpoint-between(xs, md) {
          font-size: 60px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 40px;
        }
      }
    }

    &#solutions {
      position: relative;
      margin: 120px 0;

      .gradient-bg {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#429bed+0,ea6262+100 */
        background: #429bed; /* Old browsers */
        background: -moz-linear-gradient(left, #429bed 0%, #ea6262 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #429bed 0%, #ea6262 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #429bed 0%, #ea6262 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#429bed', endColorstr='#ea6262', GradientType=1); /* IE6-9 */

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background: inherit;
          z-index: -1;
          bottom: 0;
          -ms-transform-origin: right top;
          -webkit-transform-origin: right top;
          transform-origin: right top;
          -ms-transform: skewY(-3deg); /* IE 9 */
          -webkit-transform: skewY(-3deg); /* Safari */
          transform: skewY(-3deg);

        }

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background: inherit;
          z-index: -1;
          bottom: 0;
          -ms-transform-origin: left bottom;;
          -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
          -ms-transform: skewY(-3deg); /* IE 9 */
          -webkit-transform: skewY(-3deg); /* Safari */
          transform: skewY(-3deg);
        }

        .text-rotated {
          top: -8em;
          right: 112px;
          transform-origin: top right;

          @include media-breakpoint-down(lg) {
            right: 56px;
          }
        }

        .solution {
          color: $gray-100;

          @include media-breakpoint-down(md) {
            margin-bottom: 40px;
          }

          .summary {
            max-width: 360px;
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }

    &#last-projects {
      position: relative;

      .container {
        .project {

          .image {
            > img {
              -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.4);
              -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.4);
              box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.4);
            }
          }

          .title {
            color: $gray-200;

            @include media-breakpoint-up(md) {
              -webkit-transform: translateX(-10vw);
              -moz-transform: translateX(-10vw);
              -ms-transform: translateX(-10vw);
              -o-transform: translateX(-10vw);
              transform: translateX(-10vw);
            }
          }

          .summary {
            color: rgba($gray-100, .7);
            font-size: 18px;
          }

          .text-rotated {
            color: rgba($gray-300, .7);
            bottom: 0;
            left: 28px;
            transform-origin: bottom left;

            @include media-breakpoint-down(sm) {
              position: relative;
              left: 0 !important;
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
          }

          &:nth-child(odd) {
            flex-direction: row-reverse;

            @include media-breakpoint-up(md) {

              .title {
                -webkit-transform: translateX(10vw);
                -moz-transform: translateX(10vw);
                -ms-transform: translateX(10vw);
                -o-transform: translateX(10vw);
                transform: translateX(10vw);
              }

              > .col,
              > [class*="col-"] {
                text-align: right;
              }
            }

            .text-rotated {
              left: 100%;
            }
          }
        }

        .vertical-line {
          @include media-breakpoint-down(md) {
            margin-top: 20px !important;
          }
        }

        .contact-us {

          @include media-breakpoint-down(sm) {
            padding: 20px 0 !important;
          }
        }

        > .text-rotated {
          top: calc(3em + 200px + 126px + 80px);
          left: calc(112px - 28px);
          transform-origin: top left;

          @include media-breakpoint-down(lg) {
            left: calc(56px - 28px);
          }
        }
      }
    }

    &#last-news {
      article {
        time {
          color: rgba(#e9f8ff, .8);
        }

        .title {
          line-height: 40px;
        }
      }
    }
  }
}