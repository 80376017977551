.template-platform {
  #iwp-platform {
    position: relative;
    background-color: #fff;
    color: $textColor3;

    .welcome {
      background-color: #f5f5f5;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: inherit;
        z-index: -1;
        bottom: 0;
        -ms-transform-origin: left bottom;;
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -ms-transform: skewY(-5deg); /* IE 9 */
        -webkit-transform: skewY(-5deg); /* Safari */
        transform: skewY(-5deg);
      }

      .title {
        color: $red;
        letter-spacing: 2px;
        @include media-breakpoint-up(lg) {
          font-size: 60px;
        }
      }

      .summary {
        line-height: 40px;
        margin: 32px 0 96px 0;
        color: $gray-300;

        @include media-breakpoint-up(xl){
          max-width: 290px;
        }

        @include media-breakpoint-down(md) {
          font-size: 22px;
          margin: 20px 0 40px 0;
        }
      }

      #carouselIwp {
        &.carousel {
          .carousel-inner {
            .carousel-item {
              > img {
                -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.4);
                -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.4);
                box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.4);
              }
            }
          }

          .carousel-indicators {
            position: relative;
            z-index: 15;
            display: flex;
            margin: 20px 0 0 0;
            list-style: none;

            @include media-breakpoint-up(xl) {
              position: absolute;
              flex-direction: column;
              left: 100%;
              right: auto;
              bottom: 35%;
              margin: 0 0 0 20px;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
            }
            > li {
              width: 15px;
              height: 15px;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;
              border: 1px solid rgba(#aaa, .7);
              background: transparent;
              margin: 5px 0;

              &:after {
                content: '';
                width: 7px;
                height: 7px;
                position: absolute;
                top: 3px;
                left: 3px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
              }

              &.active {
                &:after {
                  background-color: rgba(#aaa, .7);
                }
              }
            }
          }
        }
      }

      .text-bordered {
        margin-left: calc(75px + 3em);
        color: $lightBlue !important;

        &:after {
          background-color: $lightBlue !important;
        }
      }

    }

    .promotions {
      position: relative;
      z-index: 1;
      overflow: hidden;

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: #f5f5f5;
        z-index: -1;
        bottom: 0;
        -ms-transform-origin: left bottom;;
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: skew(0deg, -6deg);
        -moz-transform: skew(0deg, -6deg);
        -ms-transform: skew(0deg, -6deg);
        -o-transform: skew(0deg, -6deg);
        transform: skew(0deg, -6deg);
      }

      .promotion {
        .image {
          width: 110px;
          height: 110px;
          display: block;
          margin: 0 auto 48px auto;
        }

        .title {
          font-size: 28px;
          line-height: 1;
          font-family: $font-family-sans-serif;
          font-weight: bold;
        }

        @include media-breakpoint-down(md) {
          margin-bottom: 30px;
        }
      }
    }
  }

  #features {
    background-color: #fff !important;
    color: $textColor3 !important;

    .title {
      font-family: $font-family-sans-serif;
    }

    .description {
      padding: 100px 0;
      font-size: 26px;
      line-height: 40px;
    }

    @include media-breakpoint-down(md) {
      .summary {
        font-size: 20px;
      }

      .description {
        padding: 40px 0;
        font-size: 18px;
        line-height: 32px;
      }
    }

    .business-benefits {
      .activity {
        .title {
          font-size: 18px;

          @include media-breakpoint-up(lg) {
            max-width: 215px;
            margin: 0 auto;
          }
        }
      }
    }

    .client-benefits {
      .description {
        font-size: 22px;

        @include media-breakpoint-down(md) {
          font-size: 18px;
        }
      }

      .slider {
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: 350px;
        height: 100%;
        margin-top: 80px;

        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }

        .badge {
          position: absolute;
          z-index: 100;
          left: 30px;
          top: -23px;
          padding: 15px 25px;
          background: $textColor3;
          border-radius: 50px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 18px;
          color: #e6e6e6;
          white-space: nowrap;
        }

        .slider-slides {
          width: 100%;
          height: 100%;

          ul {
            width: 100%;
            height: 100%;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;
              height: 300px;
              background: #fff;
              font-size: 18px;
              position: absolute;
              padding: 3vh 2vw;
              border-radius: 25px;
              left: 0;
              top: 0;
              -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
              -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.3);
              box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);

              > p {
                margin: 0;
              }
            }
          }
        }

        .slider-controls {
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1000;

          .slider-arrow {
            width: 40px;
            height: 40px;
            border: none;
            background: $red;
            color: #fff;


            &.slider-arrow_prev {
              border-top-right-radius: 25px;
            }

            > i {
              vertical-align: middle;
            }
          }
        }
      }
    }

    .modules {
      background-color: #f5f5f5;
      position: relative;
      margin-top: 140px;

      @include media-breakpoint-down(lg) {
        margin-top: 40px;
      }


      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: inherit;
        bottom: 0;
        -ms-transform-origin: right bottom;;
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -webkit-transform: skew(0deg, 6deg);
        -moz-transform: skew(0deg, 6deg);
        -ms-transform: skew(0deg, 6deg);
        -o-transform: skew(0deg, 6deg);
        transform: skew(0deg, 6deg);
      }

      .list {
        background-color: #fff;
        margin: 80px 0;
        border-radius: 25px;

        .nav {
          flex-basis: 25%;
          justify-content: space-between;

          &.nav-tabs {
            border-bottom: none;

            .nav-item {
              display: flex;
              flex-direction: column;
              flex-grow: 1;

              .nav-link {
                flex-grow: 1;
                border-top-left-radius: 25px;
                border-top-right-radius: 0;
                border-color: transparent #f5f5f5 #f5f5f5 transparent;
                padding: 2rem 1rem;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f5f5f5+0,f5f5f5+100&0+0,1+100 */
                background: -moz-linear-gradient(left, rgba(245,245,245,0) 70%, rgba(245,245,245,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(245,245,245,0) 70%,rgba(245,245,245,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(245,245,245,0) 70%,rgba(245,245,245,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f5f5f5', endColorstr='#f5f5f5',GradientType=1 ); /* IE6-9 */

                .title {
                  color: $textColor1;
                  font-weight: bold;
                  font-size: 18px;
                  margin: 0;
                }

                &.active {
                  background: #fff;
                  > svg {
                    fill: $textColor3;
                  }

                  .title {
                    color: $textColor3;
                  }
                }
              }
            }
          }
        }

        .tab-content {
          text-align: left;
          padding: 1rem 2rem;
          flex-basis: 75%;

          .card {
            border: none;
            .card-header {
               display: none;
            }
          }
        }

        @media (max-width: 767px) {
          .nav {
            &.nav-tabs {
              display: none;
            }
          }

          .tab-content {
            padding: 0;

            .tab-pane {
              display: block !important;
              opacity: 1;

              &.card {
                background: #fff;

                .card-header {
                  display: block;
                  text-align: center;
                  color: $textColor1;
                  background: transparent;

                  > a {
                    display: block;

                    .title {
                      margin: 0;
                    }

                    &[aria-expanded="true"] {
                      color: $textColor3;

                      svg {
                        fill: $textColor3;
                      }
                    }
                  }

                  &:not(:last-child) {
                    border-bottom: 1px solid #f5f5f5;
                  }
                }

                .collapse {
                  .card-body {
                    border-bottom: 1px solid #f5f5f5;
                  }
                }

                &:first-child {
                  border-top-left-radius: 25px;
                  border-top-right-radius: 25px;
                }

                &:last-child {
                  border-bottom-left-radius: 25px;
                  border-bottom-right-radius: 25px;
                }
              }
            }
          }
        }

        @media(min-width:768px) {
          .nav-tabs {
            display: flex;
          }

          .card {
            border: none;
          }

          .card .card-header {
            display:none;
          }

          .card .collapse{
            //display:block;
          }
        }
      }
    }
  }

  // footer dark
  footer {

    #footer {
      color: $textColor3;
      background-color: #f5f5f5 !important;

      .title {
        color: rgba($textColor3, .7);
      }

      a {
        color: $textColor3;
      }
    }

    #copyright {
      background-color: #f5f5f5 !important;

      color: rgba(0, 0, 0, .5) !important;

      a {
        color: rgba(0, 0, 0, .5) !important;
      }
    }
  }
}