@import "variables";

// importazione Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";


@import "macros";
@import "layout";
@import "buttons";
@import "menu";
@import "forms";
@import "fonts";
@import "icomoon";
@import "home";
@import "solutions";
@import "news";
@import "about";
@import "contacts";
@import "platform";
@import "portfolio";
