// Margini e padding

$positions: (
        top: 't',
        bottom: 'b',
        left: 'l',
        right: 'r'
);

@mixin generateMpClasses($prop, $propAbbr) {
    @for $i from 0 to 20 {
        @each $position, $abbr in $positions {
            .#{$propAbbr}#{$abbr}-#{$i*8} {
                #{$prop}-#{$position}: #{$i*8}px !important;

            }
        }

        .#{$propAbbr}-#{$i*8} {
            #{$prop}: #{$i*8}px !important;
        }
    }

  @for $i from 0 to 4 {
    @include media-breakpoint-down(sm) {
      @each $position, $abbr in $positions {
        .#{$propAbbr}#{$abbr}-#{$i*8} {
          #{$prop}-#{$position}: 16px !important;
        }
      }
      .#{$propAbbr}-#{$i*8} {
        #{$prop}: 16px !important;
      }
    }
  }

  @for $i from 5 to 20 {
    @include media-breakpoint-down(sm) {
      @each $position, $abbr in $positions {
        .#{$propAbbr}#{$abbr}-#{$i*8} {
          #{$prop}-#{$position}: 40px !important;
        }
      }
      .#{$propAbbr}-#{$i*8} {
        #{$prop}: 40px !important;
      }
    }
  }
}

@include generateMpClasses('margin', 'm');
@include generateMpClasses('padding', 'p');


// template dark

$templates: ('home', 'portfolio-list', 'news-list', 'news', 'platform');
@each $template in $templates {
  .template-#{$template} {
    background-color: $darkBlue;
    color: $gray-300;

  }
}

$map: (
        navbarTogglerIcon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$gray-300}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23"),
        border: none,
        textColor1: $gray-100,
        textColor2: $gray-200,
        textColor3: $gray-300,
        textColor4: $gray-400,
        backgroundColor: $darkBlue,
        dropdownBackground:  #262f33,
        copyrightColor: rgba(255, 255, 255, .5)
);


@mixin themify($templates, $map) {
    @each $template in $templates {
        .template-#{$template} & {
            $template-map: () !global;
            @each $key, $submap in $map {
                $value: map-get($map, '#{$key}');
                $template-map: map-merge($template-map, ($key: $value)) !global;
            }
            @content;
            $template-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($template-map, $key);
}


svg {
  .logo-text, .logo-text-footer {
    @include themify($templates, $map) {
      fill: themed('textColor3') !important;
    }
  }

  .logo-line {
    @include themify($templates, $map) {
      stroke: themed('textColor3') !important;
    }
  }

  .circle {
    stroke: $textColor3;

    @include themify($templates, $map) {
      stroke: themed('textColor3') !important;
    }
  }

  .icon {
    fill: $textColor3;

    @include themify($templates, $map) {
      fill: themed('textColor3') !important;
    }
  }

  .highlight {
    fill: $red;

    @include themify($templates, $map) {
      fill: themed('textColor1');
    }
  }
}

.template-portfolio {
  svg {
    .logo-text-footer, .icon {
      fill: $gray-300 !important;
    }

    .logo-line, .circle {
      stroke: $gray-300 !important;
    }
  }
}

.template-platform {
  svg {
    .logo-text-footer, .icon {
      fill: $textColor3 !important;
    }

    .logo-line, .circle {
      stroke: $textColor3 !important;
    }
  }
}


// EFFETTO SOTTOLINEATURA TESTO
.text-underlined {
  background-repeat: repeat-x; // make it horizontal
  background-position: 0 80%; // change the second value to adjust how far from the top the underline should be
  background-size: 100% .15em; // change the second value to the desired height of the underline

  &.red {
    background-image: linear-gradient(to right, $red 0%, $red 100%);
  }

  &.teal {
    background-image: linear-gradient(to right, $teal 0%, $teal 100%);
  }
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-red {
  color: $red;
}

.text-rotated {
  position: absolute;
  font-family: $font-family-monospace;
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Safari */
  transform: rotate(-90deg);
}

.text-bordered {
  position: absolute;
  font-family: $font-family-monospace;
  font-size: 14px;

  &:after {
    content: "";
    display: inline-block;
    background-color: $textColor3;
    height: 1px;
    vertical-align: middle;

    @include themify($templates, $map) {
      background-color: themed('textColor3');
    }
  }

  &.text-bordered-top {
    position: relative;
    color: rgba($gray-300, .5);

    &:after {
      width: 200px;
      background-color: rgba($gray-300, .5);
      position: absolute;
      left: 0;
      top: -8px;
    }
  }

  &.text-bordered-right {

    &:after {
      position: relative;
      width: 200px;
      left: 3em;
      margin-right: -200px;
    }
  }

  &.text-bordered-left {

    &:after {
      position: relative;
      width: 75px;
      right: calc(100% + 3em);
      margin-left: -75px;
    }
  }
}

.no-underline {
  text-decoration: none !important
}

.text-shadow {
  text-shadow: 5px 5px 25px rgba(0,0,0,0.4);
}

.vertical-line {
  position: relative;
  height: 190px;
  border-left: 2px solid rgba(204, 204, 204, .3);
  left: 50%;
  margin-left: -1px;
}



.horizontal-line {
  width: 80px;
  height: 8px;
  background-color: rgba($textColor1,.3);
}

time {
  letter-spacing: 2px;
}

.modal {

  &.full {
    overflow: hidden !important;

    .modal-dialog {
      max-width: 100%;
      height: 100%;
      margin: 0;

      @include themify($templates, $map) {
        background-color: themed('backgroundColor');
      }

      .modal-content {
        border: none;
        border-radius: 0;

        @include themify($templates, $map) {
          background-color: themed('backgroundColor');
        }

        .modal-header {
          position: absolute;
          width: 100%;
          border-bottom: none;
          z-index: 10;


          .close {

            > svg {
              width: 40px;
              height: 40px;
              fill: #5e6a72;

              @include media-breakpoint-down(sm) {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        .modal-body {
          padding: 0 4vw;
          overflow-y: auto;
          height: 100vh;

          .title, .subtitle {
            color: $textColor2;

            @include themify($templates, $map) {
              color: themed('textColor2');
            }
          }
        }
      }
    }
  }
}