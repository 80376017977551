.template-solutions {

  #solutions {

    > div {
      border-bottom: 1px solid #eee;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @include media-breakpoint-down(md) {
        background-image: none !important;
      }

      @include media-breakpoint-up(lg) {
        .details {
          max-width: 315px;
          float: right;
        }
      }

      .solution {
        h1.title {
          font-size: 48px;
        }

        @include media-breakpoint-down(md){
          .icon {
            text-align: center !important;
          }
        }
      }
    }
  }
}

.template-solution {
  #solution {

    .item {
      position: relative;
      padding: 80px 0;

      @include media-breakpoint-down(md) {
        padding: 40px 0;
      }

      &:nth-of-type(even) {
        background: #f5f5f5;
        margin: 180px 0;

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background: inherit;
          z-index: -1;
          bottom: 0;
          -ms-transform-origin: right top;
          -webkit-transform-origin: right top;
          transform-origin: right top;
          -ms-transform: skewY(-6deg); /* IE 9 */
          -webkit-transform: skewY(-6deg); /* Safari */
          transform: skewY(-6deg);
        }

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background: inherit;
          z-index: -1;
          bottom: 0;
          -ms-transform-origin: left bottom;;
          -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
          -ms-transform: skewY(-6deg); /* IE 9 */
          -webkit-transform: skewY(-6deg); /* Safari */
          transform: skewY(-6deg);
        }
      }


      .text-rotated {
        top: 0px;
        right: 52px;
        -webkit-transform-origin: top right;
        transform-origin: top right;
        transform: rotate(-90deg);
        color: $textColor2;
      }

      .summary {
        font-size: 28px;
        font-family: $headings-font-family;
        line-height: 46px;

        @include media-breakpoint-down(md) {
          font-size: 20px;
          line-height: 32px;
        }
      }

      .description {
        font-size: 18px;
        @include media-breakpoint-down(md) {
          > p {
            font-size: inherit !important;
          }
        }
      }


      &.award_shows {
        .targets {
          > div {
            .target {
              .image {
                width: 100px;
                fill: $red;
              }

              .title {
                font-weight: bold;
                font-size: 28px;
                line-height: 1;
              }

              @include media-breakpoint-up(lg) {
                max-width: 290px;
                margin: 0 auto;

              }
            }
          }
        }
      }

      &.management {
        .targets {
          > div {
            display: flex;
            .target {
              width: 100%;
              background-color: #fff;
              padding: 48px;
              -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
              box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
              display: flex;
              flex-direction: column;

              .title {
                font-size: 32px;
                line-height: 40px;
                font-weight: bold;
              }

              ul {
                margin: 25px 0 0 0;
                padding: 0;

                > li {
                  list-style: none;
                  padding: 20px 0;

                  &:not(:last-child) {
                    border-bottom: 1px solid rgba($textColor2, .3);
                  }
                }
              }
            }
          }
        }
      }

      &.incentive {
        .targets {
          > div {
            display: flex;
            .target {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 25px;
              text-align: left;
              background-color: #fff;
              -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
              box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);

              .image {
                max-width: 26px;
                width: 100%;
                height: 26px;
                fill: $textColor3;
                margin-right: 1em;
              }

              .title {
                margin: 0;
                font-size: 22px;
                line-height: 32px;
                font-weight: bold;
              }

              @include media-breakpoint-up(lg) {
                max-width: 370px;
                width: 100%;
                margin: 0 auto;
              }
            }
          }
        }
      }

      &.control {
        .targets {
          > div {
            display: flex;
            .target {
              background-color: #fff;
              padding: 40px;

              .image {
                width: 70px;
                height: 70px;
                fill: $textColor3;
              }

              .title {
                font-size: 32px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .more-details {
      padding-bottom: 80px;

      h5 {
        font-family: $font-family-sans-serif;
        color: $textColor2;
      }
    }
  }
}