// Colori
$textColor1: #9dafb8;
$textColor2: #68747a;
$textColor3: #464e52;
$textColor4: #343a3d;
$textColor5: #232729;


$darkBlue: #1c2429;
$lightBlue: #429bed;
$red: #ea6262;
$orange: #e8735f;
$teal: #23d9bd;


$body-color: $textColor3;
$primary: $lightBlue;


// Griglia
$grid-gutter-width: 48px !default;

$container-max-widths: (
    sm: 100%,
    md: 100%,
    lg: 100%,
    xl: 1632px
) !default;


// Testo e font
$line-height-base: 2 !default;
$font-family-sans-serif: 'PT Sans', Helvetica, Arial, sans-serif;
$font-family-monospace: 'PT Mono', Consolas, Monaco, "Courier New", monospace;
$headings-font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
$logo-font-family: 'Source Sans Pro', sans-serif;
$headings-font-weight: bold;
$headings-line-height: 1;


// Buttons
$btn-padding-x:     1rem;
$btn-padding-x-sm:  0.75rem;
$btn-padding-x-lg:  1.5rem;

// varie
$border-radius:     3px !default;
$border-radius-lg:  3px !default;
$border-radius-sm:  3px !default;