html {

    body {
        font-family: $font-family-sans-serif;
        font-size: 16px;
        color: $body-color;
        background-color: #fff;
    }
}

.container {
    padding-left: 112px;
    padding-right: 112px;

    @include media-breakpoint-down(lg) {
        padding-left: 56px !important;
        padding-right: 56px !important;
    }

    @include media-breakpoint-between(xs, sm) {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}

a {
    color: $textColor3;

    @include themify($templates, $map) {
        color: themed('textColor3');
    }


    &:focus, &:hover {
        color: $textColor3;

        @include themify($templates, $map) {
            color: themed('textColor3');
        }
    }
}

h1, .h1 {
    @include media-breakpoint-up(lg) {
        font-size: 64px;
    }
}


footer {
    border-top: 1px solid rgba($textColor1, .1);


    @include media-breakpoint-down(sm) {
        text-align: center !important;
    }

    @include themify($templates, $map) {
        border-top: themed('border');
    }

    #footer, #copyright {
        background-color: #fff;
        color: $textColor3;

        @include themify($templates, $map) {
            background-color: themed('backgroundColor');
            color: themed('textColor3');
        }
    }

    #footer {

        .title {
            font-family: $font-family-sans-serif;
            font-weight: normal;
            font-size: 18px;
            color: rgba($textColor1, .7);



            @include themify($templates, $map) {
                color: rgba(themed('textColor1'), .7);
            }
        }

        .organization {
            @include media-breakpoint-down(sm) {
                margin-bottom: 32px;
            }
        }

        .social-buttons {

            .social {
                padding:0 10px;

            }
        }
    }

    #copyright {

        font-size: 14px;
        line-height: 1.2;
        color: rgba(0, 0, 0, .5) !important;

        @include themify($templates, $map) {
            color: themed('copyrightColor') !important;
        }

        a {
            color: rgba(0, 0, 0, .5) !important;

            @include themify($templates, $map) {
                color: themed('copyrightColor') !important;
            }
        }

    }
}


.skew {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 525px;
    background-color: $darkBlue;
    -webkit-transform-origin: 0;
    -moz-transform-origin: 0;
    -ms-transform-origin: 0;
    -o-transform-origin: 0;
    transform-origin: 0;
    -webkit-transform: skewY(-6deg);
    -moz-transform: skewY(-6deg);
    -ms-transform: skewY(-6deg);
    -o-transform: skewY(-6deg);
    transform: skewY(-6deg);
}
