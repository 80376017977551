form {
  .form-group {
    position: relative;
    width: 100%;
    outline: none;
    margin-top: 16px !important;
    margin-bottom: 48px;
    border-radius: 0 !important;

    .form-control {
      position: relative;
      padding: .375rem .75rem .375rem 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 0;
      border: none;
      background: none;
      border-bottom: 1px solid rgba($textColor1, .5);
      outline: none;
      background-color: inherit;
      background: -webkit-linear-gradient(bottom, $textColor1 50%, $textColor1 50%);
      background: linear-gradient(to top, $textColor1 50%, $textColor1 50%);
      background-position: left calc(100% + 1px);
      background-size: 0 1px;
      background-repeat: no-repeat;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;

      @include themify($templates, $map) {
        color: $textColor1;
        border-bottom: 1px solid rgba(themed('textColor1'), .5);
        background-color: inherit;
        background: -webkit-linear-gradient(bottom, themed('textColor1') 50%, themed('textColor1') 50%);
        background: linear-gradient(to top, themed('textColor1') 50%, themed('textColor1') 50%);
        background-position: left calc(100% + 1px);
        background-size: 0 1px;
        background-repeat: no-repeat;
      }

      &:hover, &:focus {
        border-color: $textColor1;

        @include themify($templates, $map) {
          border-color: themed('textColor1');
        }
      }

      &:focus, &:valid, &:not([value=""]):not(textarea) {
        box-shadow: none;
        background-size: 100% 1px;

        &:after {
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          -o-transform: scaleX(1);
          transform: scaleX(1);
        }

        + label {
          height: 20px;
          line-height: 1px;
          font-size: 12px;
        }

      }

      &:not(textarea) {
        height: 56px !important;
      }
    }

    label {
      color: $textColor1;
      margin-bottom: 0;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      transition: all .3s ease;
      width: 100%;
      height: 56px;
      line-height: 56px;

      @include themify($templates, $map) {
        color: themed('textColor1');
      }
    }

    &.select:after {
      content: "\ec3a";
      font-family: 'icomoon' !important;
      color: $textColor1;
      text-align: center;
      line-height: 56px;
      position: absolute;
      width: 32px;
      height: 52px;
      right: 1px;
      top: 1px;
      pointer-events: none;

    }
  }

  .privacy {
    max-height: 150px;
    color: $textColor2;
    overflow: auto;
    font-size: 12px;
    margin-top: 64px;
    margin-bottom: 32px;

    @include themify($templates, $map) {
      color: themed('textColor2');
    }
  }

  .response {
    > .alert {
      background-color: transparent !important;
      border: none !important;

      [class^="icon-"], [class*=" icon-"] {
        display: block;
        font-size: 32px;
      }
    }
  }

  &:not(.filter-projects) {

    .form-check {
      padding-left: 0;
      margin-bottom: 32px;

      .form-check-input {
        padding: 0;
        margin-bottom: 0;
        margin-left: 5px;
        margin-top: 15px;
        opacity: 0;

        &:checked {

          + label {

            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 8px;
              left: 9px;
              width: 6px;
              height: 14px;
              border: solid $textColor2;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);

              @include themify($templates, $map) {
                border-color: themed('textColor2');
              }
            }
          }
        }
      }

      label.form-check-label {
        display: block;
        position: relative;
        padding-left: 34px;
        cursor: pointer;

        .checkmark {
          position: absolute;
          top: 4px;
          left: 0;
          width: 24px;
          height: 24px;
          background-color: rgba($textColor1, .5);
          border: 1px solid rgba($textColor1, .5);
          border-radius: $border-radius;


          @include themify($templates, $map) {
            background-color: rgba(themed('textColor1'), .5);
            border: 1px rgba(themed('textColor1'), .5);
          }
        }
      }
    }
  }

  &.filter-projects {
    .form-check {
      .form-check-input {
        margin: 0 !important;
        display: none;

        &:checked {

          + .form-check-label {
            -webkit-transition: background-color .1s linear;
            -ms-transition: background-color .1s linear;
            transition: background-color .1s linear;
            background-color: $orange;
          }
        }
      }

      .form-check-label {
        text-transform: uppercase;
        padding: 5px 13px;
        border-radius: $border-radius;
        -webkit-transition: background-color .1s linear;
        -ms-transition: background-color .1s linear;
        transition: background-color .1s linear;

        &:hover {
          background-color: $orange;
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
        margin-right: 0;
        display: block;

        .form-check-label {
          width: 100%;
          border-radius: 0;
        }

        &:first-child {
          .form-check-label {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
          }
        }

       &:last-child {
         .form-check-label {
           border-bottom-left-radius: $border-radius;
           border-top-right-radius: $border-radius;
         }
       }
      }
    }
  }
}