// Projects list
.template-portfolio-list {

  // border menu
  #menu {
    &.navbar {
      border-bottom: 1px solid rgba($gray-100, .1) !important;
    }
  }

  #loader-block {
    position: fixed;
    z-index: 9999;
    background-color: rgba($darkBlue, .8);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      fill: $gray-100;
    }
  }

  .filters {
    padding-top: 64px;
    padding-bottom: 64px;
    > p {
      color: rgba($textColor1, .7);
    }

    .dropdown {

      .dropdown-toggle {
        text-decoration: none;
        color: rgba($textColor1, .7);


        > svg {

          width: 24px;
          height: 24px;
          vertical-align: middle;
          fill: $textColor1;
          fill-opacity: .7;
        }

        &:after {
          color: rgba($textColor1, .7);
        }
      }

      .dropdown-menu {
        position: absolute !important;
        background-color: #262f33 !important;
        color: $gray-300;
        border: none;
        padding: 0;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4) !important;
        -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4) !important;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4) !important;

        @include media-breakpoint-up(md) {
          position: relative !important;
          display: block;
          float: none;
          background-color: transparent !important;
          -webkit-box-shadow: none !important;
          -moz-box-shadow: none !important;
          box-shadow: none !important;
          transform: none !important;

        }
      }
    }
  }


  .projects {

    .project {

      overflow: hidden;

      &:hover{
        .overlay{
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.75) 100%);
        }
        img{
          transform: scale(1.05);
        }
      }

      img, .overlay{
        transition: all 0.2s ease-out;
      }

      > a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .overlay {
          position: absolute;
          width: calc(100% + 1px);
          height: calc(100% + 1px);
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 100%);

          .text {
            position: absolute;
            width: 100%;
            bottom: 0;
            padding: 2vh 2vw;

            @include media-breakpoint-down(md) {
              padding: 2vh 4vw;
            }

            .client {
              color: #ffffff;
              letter-spacing: .1em;
            }

            .title {
              font-family: $font-family-sans-serif;
            }
          }
        }

      }
    }

    .pagination {

      ul {
        margin: 0 auto;
        -webkit-padding-start: 0px;
        -moz-padding-start: 0px;

        > li {
          display: inline-block;
          list-style: none;
          margin: 0 1px 0 0;

          &[aria-current="true"] {
            a {
              background-color: $orange;
            }
          }

          a {
            display: block;
            width: 32px;
            float: left;
            padding: 0px 10px;
            text-align: center;
            border-radius: $border-radius;
            text-decoration: none;

            &:hover {
              background-color: $orange;
            }
          }
        }
      }
    }
  }
}

// Project details
.template-portfolio {

  #portfolio {

    .details {
      @include media-breakpoint-up(lg) {
        padding: 3vh 3vw;
        
        .title {
          text-shadow: 2px 2px 10px rgba(0,0,0,0.2);
        }

        .summary {
          max-width: 63%;
        }
      }

      .text-bordered {
        color: $lightBlue !important;

        &:after {
          background-color: $lightBlue !important;
          height: 2px;
        }

        @include media-breakpoint-up(lg) {
          left: calc(50% - 150px);
        }
      }
    }

    .image {

      @include media-breakpoint-up(lg) {
        -webkit-transform: translateX(-30%);
        -moz-transform: translateX(-30%);
        -ms-transform: translateX(-30%);
        -o-transform: translateX(-30%);
        transform: translateX(-30%);
        padding-left: 0;
        padding-right: 0;
      }

      > img {
        opacity: 0.88;
        filter: alpha(opacity=88); /* For IE8 and earlier */
        -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
      }
    }

    .description {

      .sector {
        color: rgba($textColor3, .5);
      }


      .text {
        font-size: 18px;

        @include media-breakpoint-down(md) {
          margin: 40px 0;
        }
      }

      .text-rotated {
        top: calc(200px + 3em);
        right: -16%;
        transform-origin: top right;
        color: rgba($textColor3, .5);

        &:after {
          background-color: rgba($textColor3, .5);
        }
      }
    }


    .slideshow {

      .carousel-control-prev, .carousel-control-next {
        font-size: 300%;
        color: $textColor2;
      }

      .carousel-control-prev {
        left: -15%;
      }

      .carousel-control-next {
        right: -15%;
      }

      .carousel-indicators {
        position: initial;
        margin-top: 40px;

        > li {
          width: 15px;
          height: 15px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          background-color: rgba($textColor2, .5);
          margin: 0 5px;

          &.active {
            background-color: $textColor2;
          }
        }
      }
    }
  }

  #project {
    background-color: $textColor3;
    color: $gray-200;

    .subtitle {
      font-size: 18px;
      color: rgba($gray-200, .7);
    }
  }

  #navigation {
    background-color: $textColor4;
    color: $gray-200;

    .title {
      font-size: 18px;
    }

    .prev, .next {
      font-size: 24px;
      color: rgba($gray-200, .5);
      text-decoration: none;
    }

    .prev {
      margin-right: 24px;
    }

    .next {
      margin-left: 24px;
    }


    .text {
      margin-top: 8px;

       p:first-child {
        font-size: 14px;
        color: rgba($gray-200, .5);
      }
    }
  }

  #footer, #copyright {
    background-color: $darkBlue;
    color: $gray-300;
  }

  // footer dark
  footer {
    border-top: none;

    #footer {

      .title {
        color: rgba($gray-100, .7);
      }

      a {
        color: $gray-300;
      }
    }

    #copyright {
      color: rgba(255, 255, 255, .5) !important;

      a {
        color: rgba(255, 255, 255, .5) !important;
      }
    }
  }
}