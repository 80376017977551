.template-contacts {

  #contacts {

    .title {
      color: $textColor2;
    }

    .info {
      > div {
        color: $textColor1;
      }

      p {
        line-height: 40px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
        order: -1;
      }
    }

    #map {
      -webkit-box-shadow: 2px 2px 35px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 2px 2px 35px 0px rgba(0,0,0,0.2);
      box-shadow: 2px 2px 35px 0px rgba(0,0,0,0.2);
    }
  }
}