header {
  position: relative;
  width: 100%;
  z-index: 1010;

  &.fixed {
    position: fixed;

    #menu {
      &.navbar {
        padding: 16px 0;
        border-bottom: 1px solid rgba($textColor1, .1) !important;

        @include themify($templates, $map) {
          border-bottom: 1px solid rgba($gray-100, .1) !important;
        }

        .navbar-nav {
          .nav-item {
            .nav-link {
              padding: 24px 32px;

              @media (min-width: 991px) and (max-width: 1301px) {
                padding: 24px 20px;
              }
            }
          }
        }
      }
    }
  }



  #menu {
    &.navbar {
      background-color: #fff;
      border-bottom: 1px solid rgba($textColor1, .1);
      padding: 64px 0;
      -webkit-transition: all .4s;
      -moz-transition: all .4s;
      -ms-transition: all .4s;
      -o-transition: all .4s;
      transition: all .4s;
  
      
  
      @include media-breakpoint-down(xs) {
        > .container {
          padding-left: 24px !important;
          padding-right: 24px !important;
        }
      }
  
      @include themify($templates, $map) {
        background-color: themed('backgroundColor');
        border-bottom: themed('border');
      }
  
      .navbar-brand {
        svg {
          vertical-align: middle;
  
          @include media-breakpoint-down(xs) {
            width: 190px;
            height: 27px;
  
          }
        }
      }
  
      .navbar-collapse {
        margin-top: 9px;
      }
  
      .navbar-toggler {
        border: none;
        color: $gray-100;
  
        .navbar-toggler-icon {
          background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$textColor3}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
  
          @include themify($templates, $map) {
            background-image: themed('navbarTogglerIcon') !important;
          }
        }
      }
  
      .navbar-nav {
  
        .nav-item {
  
          &.dropdown {
  
            .dropdown-menu {
              background-color: #ffffff;
              border: none;
              padding: 0;
              margin: 8px 0 0;
              white-space: normal;
              -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
              -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
              box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  
              @include themify($templates, $map) {
                background-color: themed('dropdownBackground');
              }
  
              &:after {
                content: "";
                position: absolute;
                top: -10px;
                left: 8%;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #ffffff;
  
                @include themify($templates, $map) {
                  border-bottom: 10px solid themed('dropdownBackground');
                }
              }
  
              .dropdown-item {
                font-family: $headings-font-family;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                color: rgba($textColor3, .8);
                padding: 0 40px;
                margin: 40px 0;
                min-width: 230px;
                width: 100%;
                white-space: normal;
  
                &:not(:last-child) {
                  border-right: 1px solid rgba(0,0,0,0.05);
  
                  @include themify($templates, $map) {
                    border-right: 1px solid rgba(255,255,255, .05);
                  }
                }
  
                @include themify($templates, $map) {
                  color: rgba(themed('textColor3'), .8);
                }
  
                .solution-types {
                  display: block;
                  line-height: 16px;
                  font-family: $font-family-sans-serif;
                  font-weight: normal;
                  font-size: 14px;
                  color: rgba($textColor3, .8);
  
                  @include themify($templates, $map) {
                    color: rgba(themed('textColor3'), .8);
                  }
                }
  
                &:hover, &:focus, &:active {
                  background-color: transparent;
                }
              }
            }
  
  
            &:hover {
              .dropdown-menu {
                display: inline-flex;
              }
            }
          }
  
          .nav-link {
            font-family: 'PT Sans Narrow', sans-serif;
            font-weight: bold;
            color: rgba($textColor3, .8);
            text-transform: uppercase;
            padding: 32px;
            -webkit-transition: padding .4s;
            -moz-transition: padding .4s;
            -ms-transition: padding .4s;
            -o-transition: padding .4s;
            transition: padding .4s;
  
            @media (min-width: 991px) and (max-width: 1301px) {
              padding: 20px;
            }
  
            @include themify($templates, $map) {
              color: rgba(themed('textColor3'), .8) !important;
  
            }
  
          }
        }
      }
  
      .social-buttons {
  
        a.social {
          padding-left: 40px;
  
          @media (min-width: 991px) and (max-width: 1301px) {
            padding-right: 10px;
            padding-left: 10px;
          }
  
          svg {
            vertical-align: middle;
            fill: rgba($textColor3, .8);
  
            @include themify($templates, $map) {
              fill: rgba(themed('textColor3'), .8);
              
            }
          }
        }
      }
    }
  }
}

#menuModal {

  ul.menu {
    font-family: $headings-font-family;
    font-weight: bold;

    > li {
      font-size: 48px;
      line-height: 3ex;

      @include media-breakpoint-down(sm) {
        text-align: center;
        font-size: 32px;
      }

      a {
        text-transform: uppercase;
      }
    }
  }

  .details {
    font-size: 18px;

    h4 {
      font-family: $font-family-sans-serif;
      font-weight: normal;
      line-height: 1.5;
      margin: 40px 0;
    }

    .contacts {
      i[class^="icon-"], [class*=" icon-"] {
        margin-right: 20px;
        color: #888888;
      }
    }
  }
}