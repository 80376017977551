.template-news-list {

  #news-list {
    position: relative;
    background-color: #fff;
    color: $textColor3;



    article {
      > div {
        .news {
          background-color: #fff;
          border-radius: $border-radius;
          padding: 48px;
          min-height: 224px;
          height: 100%;
          -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);

          .title {
            line-height: 40px;
            color: $textColor3;
          }
        }
      }
    }

    .pagination {

      ul {
        margin: 0 auto;
        padding: 0;

        > li {
          display: inline-block;
          list-style: none;
          margin: 0 1px 0 0;

          &[aria-current="true"] {
            > a {
              background-color: $darkBlue;
              color: $gray-300;
            }
          }

          a {
            display: block;
            width: 32px;
            float: left;
            padding: 0px 10px;
            text-align: center;
            border-radius: 3px;
            text-decoration: none;
            color: $textColor3;

            &:hover {
              background-color: $darkBlue;
              color: $gray-300;
            }
          }
        }
      }
    }
  }
}

.template-news {

  #news {
    position: relative;
    background-color: #fff;
    color: $textColor3;

    article {

      .title {
        line-height: 40px;
        color: $textColor3;
      }

      .news-list-link {
        color: $textColor1;
        text-decoration: none;
        font-size: 30px;
        margin-left: 24px;
      }

      .details {
        background-color: #fff;
        border-radius: $border-radius;
        padding: 48px;

      }
    }

    .more-details {
      h5 {
        font-family: $font-family-sans-serif;
        color: $textColor2;
      }
    }
  }
}
