.template-about {

  #about {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    .title {
      color: $textColor2;
    }

    .description {
      line-height: 2;
      font-family: $font-family-sans-serif;
      margin: 120px 0 0 120px;



      .logo {
        font-family: $logo-font-family;
        font-weight: 600;
        text-transform: uppercase;
      }
      @include media-breakpoint-down(md) {
        margin: 6vh 0 0;
      }
    }
  }
}